<!--  -->
<template>
  <div class="left">
    <Title >
      <template v-slot:name>
        销量统计
      </template>
    </Title>
    <div class="bar">
      <div class="barItem zu1">
        <div class="barItemTitle">本中心自营</div>
        <div class="unit">
          <span class="blue">{{ selfSum }}</span
          >份
        </div>
      </div>
      <div class="barItem zu2">
        <div class="barItemTitle">周边农户</div>
        <div class="unit">
          <span class="green">{{ outsideSum }}</span
          >份
        </div>
      </div>
    </div>
    <div class="ranking">
      <myCharts :pdata="listData"></myCharts>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { saleCount, saleYear } from "@/api/sales/sales";
import myCharts from "./myCharts.vue";
import Title from "./titletab.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    myCharts,
    Title
  },
  data() {
    //这里存放数据
    return {
      selfSum: "",
      outsideSum: "",
      imgData: [],
      active: [],
      listData: [
      
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getData() {
      saleCount().then((res) => {
        if (res.code == 1) {
          this.selfSum = res.data.selfSum;
          this.outsideSum = res.data.outsideSum;
          this.listData = res.data.ranking.map((item,index) => {
            return {
              name: item.index,
              label: item.name,
              value: item.count,
            };
          });
        }
      });
      saleYear().then((res) => {
        if (res.code == 1) {
          // console.log(res);
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

//@import url(); 引入公共css类
::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.left {
  margin-top: companyH(20vh);
  border: 1px solid #6d8a94;
  width: companyW(450vw);
  height: companyH(959vh);
  overflow: hidden;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */

  .title {
    height: companyH(35vh);

    background-size: 117% 300%;
    background-position: companyH(-20vw) companyH(-35vh);
    background-repeat: no-repeat;
  }
  .bar {
    display: flex;
    justify-content: center;
    margin-top: companyH(25vh);
    .zu1 {
      background: url(../../../assets/sales/zu1.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .zu2 {
      background: url(../../../assets/sales/zu2.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .barItem {
      width: companyW(184vw);
      height: companyH(82vh);
      margin-right: companyW(20vw);
      .blue {
        font-size: 20px;
        color: #23b9ff;
        margin-right: companyW(10vw);
      }
      .green {
        font-size: 20px;
        color: #23fffc;
        margin-right: companyW(10vw);
      }
      .barItemTitle {
        margin-top: companyH(10vh);
        padding: 0 0 companyH(10vh) companyW(20vw);
        color: white;
      }
      .unit {
        font-size: 12px;
        color: #a4a7a9;
        padding-left: companyW(20vw);
      }
    }
    .barItem:nth-of-type(2) {
      margin-right: 0;
    }
  }
  .ranking {
    height: companyH(782vh);
    overflow-y: auto;
    padding: 0 companyW(40vw) 0 companyW(20vw);
  }
}
</style>
