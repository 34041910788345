<template>
  <div class="video">
    <div class="title"></div>
    <div class="videoBox" ref="scrollview">
      <div class="videoItem" v-for="(item,index) in videoList" :key="item.id">
        <videoPlay :videoUrl="item.videoUrl"/>
      </div>
    </div>
  </div>
</template>

<script>

import { getVideos } from "@/api/sales/sales";
import videoPlay from "./videoItem.vue";
export default {
  data(){
    return {
      page:{
        pageNum:1,
        pageSize:9,
        videoType:3
      },
      videoList:[]
    }
  },
  components:{
    videoPlay
  },
  mounted(){
    // 获取指定元素
    const scrollview = this.$refs['scrollview']
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    scrollview.addEventListener('scroll', this.handleScroll, true)
    this.getVideo()
  },
  methods:{
    getVideo(){
      getVideos(this.page).then((res) => {
        if (res.code == 1) {
          this.videoList = [...this.videoList,...res.data.records]
        }
      })
      
    },
    handleScroll(e){
      const {scrollTop, clientHeight, scrollHeight} = e.target
      console.log(scrollTop+clientHeight, scrollHeight);
      if (scrollHeight - (scrollTop + clientHeight) <2 ){
        this.page.pageNum+=1
        this.getVideo()
      }

      // const scrollY = window.scrollY;
      // const windowHeight = window.innerHeight;
      // const documentHeight = document.documentElement.scrollHeight;
      // const bottomOfPage = windowHeight + scrollY >= documentHeight;
      // if (bottomOfPage && this.visibleItems.length < this.totalItems) {
      //   this.loadMoreItems();
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}

//@import url(); 引入公共css类
.video {
  width: companyW(940vw);
  padding: 0 companyW(30vw);
  height: companyH(414vh);
  .title {
    height: companyH(35vh);
    background-image: url(../../../assets/sales/hdhg.png);
    background-size: 117% 300%;
    background-position: companyH(-20vw) companyH(-35vh);
    background-repeat: no-repeat;
  }
  .videoBox {
    height: companyH(380vh);
    display: flex;
    flex-wrap: wrap;
    padding: companyW(25vw);
    overflow: auto;
    .videoItem {
      width: companyW(281vw);
      height: companyH(185vh);
      background-color: #fff;
      margin: 0 companyW(15vw) companyH(10vh) 0;
    }
  }
  /* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
  .videoBox::-webkit-scrollbar {
    display: none;
  }

  /* 为 IE、Edge 和 Firefox 隐藏滚动条 */
  .videoBox {
    -ms-overflow-style: none; /* IE 和 Edge */
    scrollbar-width: none; /* Firefox */
  }
}
</style>
