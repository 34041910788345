<!--  -->
<template>
    <div >
        <tables />
       
        <charts />
       
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {cropInfo} from '@/api/sales/sales'
import tables from './table.vue'
import charts from './charts.vue'
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {
        tables,
        charts
    },
    data() {
        //这里存放数据
        return {
            tableData:[]
        };
    },
    //监听属性 类似于data概念
    computed: {
        classOption() {
            return {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: 18, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        getData(){
            cropInfo().then(res=>{
                if(res.code==1){
                    this.tableData = res.data
                }
            })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // this.getData()
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

//@import url(); 引入公共css类
.box {
  
}
</style>