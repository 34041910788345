<template>
    <div>
        <carousel />
        <videos />
    </div>
</template>
<script>
    import carousel from './carousel.vue'
    import videos from './video.vue'
    export default {
        components:{
            carousel,
            videos
        }
    }
</script>