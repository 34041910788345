<!--  -->
<template>
  <div class="right">
    <Title >
      <template v-slot:name>
        实时订单
      </template>
    </Title>
    <div class="contentTitle">
      <div>经营者名称</div>
      <div>商品及数量</div>
      <div>销售时间</div>
    </div>
    <!-- <vue-seamless-scroll :data="tableData" class="seamless-warp" :class-option="classOption" v-if="tableData&&tableData.length > 0"> -->
    <div class="contentItem" v-for="(item, index) in tableData" :key="index"> 
      <div class="contentItems" :title="item.shopName">
        {{ item.shopName | text }}
      </div>
      <div class="contentItems" :title="item.productName">
          {{ item.productName | text  }}{{ '*'+ item.count }}
          <span></span>
      </div>
      <div class="contentItems">{{ item.orderTime | orderTime}}</div>
    </div>
    <!-- </vue-seamless-scroll> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { order } from "@/api/sales/sales";
import Title from "./titletab.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {Title},
  data() {
    //这里存放数据
    return {
        gettableData:null,
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  // computed: {
  //     classOption() {
  //         return {
  //             step: 1, // 数值越大速度滚动越快
  //             limitMoveNum: 18, // 开始无缝滚动的数据量 this.dataList.length
  //             hoverStop: true, // 是否开启鼠标悬停stop
  //             direction: 1, // 0向下 1向上 2向左 3向右
  //             openWatch: true, // 开启数据实时监控刷新dom
  //             singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
  //             singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  //             waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
  //         }
  //     }
  // },
  //监控data中的数据变化
  watch: {},
  filters:{
    text(e){
        if(e&&e.length>5){
            return e.slice(0,5) + '...'
        }else{
            return e
        }
    },
    orderTime(e){
        if(e&&e){
            return `${e.slice(5,10)} ${e.slice(11,16)}`
        }
    }
  },
  //方法集合
  methods: {
    getData() {
      order().then((res) => {
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getData()
    this.gettableData && clearInterval(this.gettableData)
     this.gettableData = setInterval(()=>{
        this.getData()
    },60000)
    ;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    clearInterval(this.gettableData)
    this.gettableData = null
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

//@import url(); 引入公共css类
.right {
  margin-top: companyH(20vh);
  border: 1px solid #6d8a94;
  width: companyW(450vw);
  height: companyH(459vh);
  .title {
    height: companyH(35vh);
    background-image: url(../../../assets/sales/ssdd.png);
    background-size: 115% 300%;
    background-position: companyH(-20vw) companyH(-35vh);
    background-repeat: no-repeat;
    margin-bottom: 10px;
  }
  .seamless-warp {
    height: companyH(400vh);
    overflow: hidden;
  }
  .contentTitle {
    height: companyH(35vh);
    line-height: companyH(35vh);
    background-size: 105% 120%;
    background-position: -10px 0;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
    color: #a8c3e6;
    border: 1px solid #1f2b43;
    border-top: none;
    div {
        text-align: center;
      flex: 1;

    }
  }
  .contentItem {
    display: flex;
    justify-content: space-around;
    color: #fff;
    border: 1px solid #1f2b43;
    height: companyH(35vh);
    line-height: companyH(35vh);
    overflow-y: hidden;
    .contentItems {
        text-align: center;
      flex: 1;
      overflow: hidden;
      
    }
  }
}
</style>
