<!--  -->
<template>
  <div class="right" >
    <Title >
      <template v-slot:name>
        中心理念销售情况
      </template>
    </Title>
    <div class="content">
      <Charts :option="option"></Charts>
    </div>
    
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Charts from "../../../components/echarts/index.vue";
import { saleYear } from "@/api/sales/sales";
import Title from "./titletab.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Charts,
    Title
  },
  data() {
    //这里存放数据
    return {
      tableData: [],
      option: {},
    };
  },
  //监听属性 类似于data概念

  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getMaxValue(arr) {
      const max = Math.max(...arr);
      // 这样处理是为了不让最大值刚好到坐标轴最顶部
      return Math.ceil(max / 9.5) * 10;
    },
    getMinValue(arr) {
      const min = Math.min(...arr);
      // 这样处理是为了不让最大值刚好到坐标轴最底部
      return Math.floor(min / 12) * 10;
    },
    getOption(res) {
      let xText = [];
      xText = res.data.map((item) => {
        return item.year;
      });
      xText.reverse();

      const colors = ["#3792C9", "#189A98", "#EE6666"];
      const data1 = res.data.map((item) => {
        return item.amount;
      });
      const data2 = res.data.map((item) => {
        return item.count;
      });
      const min1 = this.getMinValue(data1),
        min2 = this.getMinValue(data2),
        max1 = this.getMaxValue(data1),
        max2 = this.getMaxValue(data2);
      this.option = {
        legend: {
          // 使用 series 的 name
          data: ["销售金额", "销售数量"],
          textStyle: {
            color: "#fff", //字体颜色
          },
        },
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        grid: {
          right: "10%",
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // restore: { show: true },
            // saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#ffffff",
              },
            },
            // prettier-ignore
            data: xText,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            nameText: "",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed", //虚线
                color: "#0C314B",
              },
              show: true, //隐藏
            },
            min: min1,
            max: max1,
            splitNumber: 5,
            interval: (max1 - min1) / 5,
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "",
            position: "right",
            alignTicks: true,
            nameText: "",
            splitNumber: 5,
            interval: (max2 - min2) / 5,
            min: min2,
            max: max2,
            splitLine: {
              lineStyle: {
                type: "dashed", //虚线
                color: "#0C314B",
              },
              show: true, //隐藏
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1],
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "销售金额",
            type: "bar",
            data: data1,
            barWidth: "10%",
          },
          {
            name: "销售数量",
            type: "bar",
            yAxisIndex: 1,
            data: data2,
            barWidth: "10%",
          },
        ],
      };
    },
    async getData() {
      await saleYear().then((res) => {
        if (res.code == 1 && res.data && res.data.length) {
          this.getOption(res);
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

//@import url(); 引入公共css类
.right {
  margin-top: companyH(20vh);
  border: 1px solid #6d8a94;
  width: companyW(450vw);
  height: companyH(459vh);
 
  .title {
    height: companyH(35vh);
    background-image: url(../../../assets/sales/wuzititle.png);
    background-size: 115% 300%;
    background-position: companyH(-20vw) companyH(-35vh);
    background-repeat: no-repeat;
    margin-bottom: 10px;
  }
  .seamless-warp {
    height: companyH(400vh);
    overflow: hidden;
  }
  .content{
    height: companyH(414vh);
  }
  .contentTitle {
    height: companyH(35vh);
    line-height: companyH(35vh);
    background-size: 105% 120%;
    background-position: -10px 0;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    color: #adb3b7;
  }
  .contentItem {
    display: flex;
    justify-content: space-around;
    color: #fff;
    height: companyH(35vh);
    line-height: companyH(35vh);
    margin-bottom: 10px;
  }
}
</style>
