<template>
  <div class="carousel">
    <el-carousel >
      <el-carousel-item v-for="(item,index) in carouselList" :key="index">
        <!-- <h3 class="small">{{ item }}</h3> -->
         <div class="img" >
          <div class="imgitem" v-for="items in item" :key="items.id">
            <img :src="items.img" alt="">
            <div class="name">{{ items.name }}</div>
          </div>
       
         </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { productInfo } from "@/api/sales/sales";
export default {
  data(){
    return {
      // img
      carouselList:[]
    }
  },
  mounted(){},
  methods:{
    //
    spArr(arr,num){
      let newList = []
      for(let i = 0; i < arr.length;){
        newList.push(arr.slice(i,i+=num))
      }
      return newList
    },
    getData(){
      productInfo().then((res) => {
        if (res.code == 1 && res.data && res.data.length) {
            this.carouselList = this.spArr(res.data,9)
            console.log(this.carouselList);
        }
      });
    }
  },
  mounted(){
    this.getData()
  }
}
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

//@import url(); 引入公共css类
.carousel {
  width: companyW(940vw);
  height: 100%;
  padding: 0 companyW(30vw);
  
}
.img{
  width: 100%;
  height: 100%;

  background-image: url('./../../../assets/sales/carousel.png');
  background-size: 100% 50%;
  background-repeat: repeat;
  display: flex;
  flex-flow: wrap;
}
.imgitem{
  position: relative;
  width: companyW(100vw);
  height: companyH(100vh);
  margin-top: companyH(10vh);
  margin-left: companyW(80vw);
  img{
    width: companyW(100vw);
    height: companyH(100vh);
    border-radius: 10px;
  }
  .name{
    position: absolute;
    bottom:companyH(-60vh);
    text-align: center;
    width: 100%;
    color: #fff;
  }
}
.imgitem:nth-of-type(5n + 1){
  margin-left: companyW(63vw);
 
}




</style>
