<!--  -->
<template>
  <div class="">
    <video ref="videoPlayer" style="width: 100%" class="video-js">
      <source v-if="videosrc" :src="videosrc" type="application/x-mpegURL" />
    </video>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import 'videojs-contrib-hls'
import Videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      videosrc: ''
    }
  },
  props: {
    videoUrl: Object
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    videoUrl: {
      handler (newData, oldData) {
        this.getVideo()
      },
      deep: true
    }
  },

  //方法集合
  methods: {
    //获取视频
    getVideo () {
      this.videosrc = this.videoUrl

      this.$nextTick(() => {
        Videojs(this.$refs.videoPlayer, {
          muted: true, //muted : true/false 是否静音
          controls: true,
          BigplayButton: false,
          autoplay: true,
          fluid: true, //Video.js播放器将具有流畅的大小。换句话说，它将扩展以适应其容器。
          // aspectRatio: "10:6",
          aspectRatio: '16:9',
          fullscreen: {
            options: {
              navigationUI: 'hide'
            }
          },
          techOrder: ['html5'], // 兼容顺序
          flvjs: {
            mediaDataSource: {
              isLive: false,
              cors: true,
              withCredentials: false
            }
          }
        })
      })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getVideo()
    // console.log(this.videoUrl,'videoUrl')
  },
  beforeCreate () {}, //生命周期 - 创建之前
  beforeMount () {}, //生命周期 - 挂载之前
  beforeUpdate () {}, //生命周期 - 更新之前
  updated () {}, //生命周期 - 更新之后
  beforeDestroy () {}, //生命周期 - 销毁之前
  destroyed () {}, //生命周期 - 销毁完成
  activated () {} //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
