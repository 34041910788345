<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      njTitle="top"
      typeOf="sales"
      :title="address+'农产品展销'"
    ></Title>
    <div class="box">
      <left />
      <middle />
      <right />
    </div>
  </div>
</template>

<script>
import Title from "./components/title.vue"
import left from './components/left.vue'
import right from './components/right.vue'
import middle from './components/middle.vue'
export default {
  name: "Sales",
  components: {
    Title,
    left,
    right,
    middle
  },
  data() {
    return {
      address: '',
    };
  },
  created() {},
  mounted() {
    this.address = localStorage.getItem('address');
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 companyW(31vw);

  }
.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  background-image: url(../../assets/sales/bgc.png);
  background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;

  .dyr-service-title {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
