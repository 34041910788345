import request from '@/utils/request.js';
//农作物统计信息
export function cropInfo(data) {
    return request({
        url: `/agrProExh/cropInfo`,
        method: 'get',
        data,
    });
}
//区域内作物
export function cropArea(data) {
    return request({
        url: `/agrProExh/cropArea`,
        method: 'get',
        data,
    });
}
//销量统计
export function saleCount(data) {
    return request({
        url: `/product/saleCount`,
        method: 'get',
        data,
    });
}
//中心历年销售情况
export function saleYear(data) {
    return request({
        url: `/product/saleYear`,
        method: 'get',
        data,
    });
}

//获取农技视频信息
export function getVideos(data) {
    return request({
        url: `/video/getVideos`,
        method: 'get',
        params:data,
    });
}
//实时订单
export function order(data) {
    return request({
        url: `/product/order`,
        method: 'get',
        params:data,
    });
}

//农产品图片简介
export function productInfo(data) {
    return request({
        url: `/product/productInfo`,
        method: 'get',
        params:data,
    });
}




